@import '../../../../styles/common.scss';
.loader {
  height: 0px !important;
}

.hContainer {
  text-align: center;
  padding: 2rem;
  clear: left;

  .question {
    @extend .body-m;
    color: var(--text-primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }
}

.button {
  margin: 0 auto;
  // margin-bottom: 3rem;
}

.customer {
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #afafaf;
}

.voucherOfferCardContent {
  padding: 1rem;
  margin: 1rem;
  background-color: #fff;
}

.printContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .voucherOfferCard {
    margin-bottom: 2rem;
  }
}

.voucherOfferPreviewButtons {
  width: 85%;
  padding-top: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .voucherOfferPreviewButtons {
    width: auto;
    flex-direction: column-reverse;
  }
}
