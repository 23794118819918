@import '../../../styles/common.scss';

.fullCenter {
  padding: 6rem 0 3rem;
}

.hContainer {
  text-align: center;
  padding: 2rem;
  clear: left;

  .question {
    @extend .body-m;
    color: var(--text-primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }
}

.formContainer {
  button {
    margin: 0 auto;
  }
}

.tou {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;

  input {
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
  }

  .label {
    padding: 0.25rem 0 0 0.5rem;

    .link {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}

.preRegisterSuccessMessage {
  padding: 2rem 0;
  font-size: 1.5rem;
  text-align: 'center';
}

@media only screen and (max-width: 767px) {
  .lineStep {
    padding: 8rem 0 4rem !important;
  }
}

/* .helpModal {
  padding: 0.75rem;

  .modalParts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #c8c8c8;
    padding-top: 0.5rem;
    > h3 {
      line-height: 2rem !important;
    }
  }
} */
