.mainDiv {
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.9375rem;
  padding: 1rem;
  filter: drop-shadow(8px 24px 60px rgba(167, 170, 196, 0.24));
  z-index: 500;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    .text {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.5rem;
      line-height: 1.125rem;
    }

    .checkIcon {
      color: #56b55a;
    }
    .timesIcon {
      color: #ff3737;
    }
  }
}

@media screen and (max-width: 768px) {
  .mainDiv {
    display: none;
  }
}
