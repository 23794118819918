.fullCenter {
  padding-bottom: 1rem;
}

.ApplicationsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  min-height: 20rem;
  height: calc(100% - 4.5rem);
}
.header {
  margin: 1rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.loadWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadButton {
  background-color: var(--primary-color);
  border-radius: 0.5rem;
  cursor: pointer;
  color: #fff;
  padding: 1rem;
  width: 6rem;
  text-align: center;
  margin: 1rem;
}

.loadMoreLoading {
  color: var(--primary-color);
}
