.poiInfoText {
  width: 100%;
  min-width: 20rem;
  padding: 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-origin: border-box;
  // border-right: 0.0625rem solid rgba(0, 0, 0, 0.12);

  .poiTitle {
    position: relative;
    max-width: 100%;
    padding: 0 0 0.5rem;
    letter-spacing: 0.03125rem;
    text-align: center;
    font-weight: 500;
  }

  .bookaTable {
    text-align: center;
    padding-bottom: 1rem;
  }

  .stepDayInfo {
    display: flex;
    align-items: center;
    .partOfDay {
      font-size: 0.75rem;
      // padding: 0.625rem 0.9375rem;
    }
  }

  .bull {
    opacity: 0;
  }

  .globalRating {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;

    .ratingTitle {
      font-size: 0.85rem;
      padding-right: 0.25rem;
      color: #888;
      font-weight: 300;
      letter-spacing: 0.2px;
    }
    .ratingContent {
      display: flex;
      align-items: center;
      text-align: justify;
      font-size: 0.75rem;

      .ratingCount {
        font-size: 0.85rem;
        padding-right: 0.25rem;
        color: #888;
        font-weight: 300;
        margin-right: 0.25rem;
      }
      // .bull {
      //   line-height: 0.5rem;
      //   font-size: 2.25rem;
      //   padding-bottom: 0.25rem;
      //   opacity: 0.5;
      // }
    }
  }

  .poiInfo {
    width: 100%;
    display: block;
    padding: 0rem 0.625rem;
    color: #71838d;

    ul {
      padding: 0;

      li {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;

        > div {
          margin: 0rem 0.3125rem 0rem 0.625rem;
          /* padding: 1.25rem 0.5rem; */
          /* text-align: right; */
          border-radius: 0.125rem;
        }
        .header {
          display: none;
          font-size: 0.8rem;
          width: 4rem;
          font-weight: bold;
        }

        .icon {
          display: flex;

          svg {
            path {
              fill: #93abb8;
            }
          }
        }
        /* .hoursIcon {
          background-image: url(../img/clock.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .cuisinesIcon {
          background-image: url(../img/place_detail_cuisine_bold@2x.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .mustTryIcon {
          // background-image: url(../img/must.png);
          // background-image: url(../img/star.png);
          background-image: url(../img/star2.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .featuresIcon {
          background-image: url(../img/hashtag.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .phoneIcon {
          background-image: url(../img/phone.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .webIcon {
          background-image: url(../img/link.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .priceIcon {
          background-image: url(../img/dollar.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }
        .addressIcon {
          background-image: url(../img/pin.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        }

        .safetyIcon {
          background-image: url(../img/safetyIcon.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 1rem;
          width: 1rem;
        } */

        .content {
          display: block;
          align-items: center;
          // text-align: justify;
          font-size: 0.9rem;
          width: calc(100% - 2rem);
          overflow: hidden;
          letter-spacing: 0.5px;

          a {
            text-decoration: none;
            color: #71838d;
          }

          input[type='checkbox'] {
            display: none;
          }

          .hoursContent {
            width: 100%;
            position: relative;
            cursor: pointer;

            .headerDay {
              font-weight: 600;
              padding-right: 0.8rem;
            }

            .headerClock {
              padding-right: 0.5rem;
            }

            .clock {
              padding-left: 0.5rem;
              text-align-last: justify;
            }

            .openedHours {
              display: flex;
              letter-spacing: 1px;

              > table {
                margin-right: 0.5rem;
              }

              .arrowup {
                margin-top: 5px;
              }
            }
          }

          .addressContent {
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .addressToggle:checked + .addressContent {
            display: block;
          }

          .featuresContent {
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .featuresToggle:checked + .featuresContent {
            display: block;
          }

          .web {
            > a {
              text-decoration: none;
              overflow: hidden;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .phone {
            > a {
              text-decoration: none;
              overflow: hidden;
              display: block;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
        }
        .description {
          font-size: 0.9rem;
          text-align: justify;

          input[type='checkbox'] {
            display: none;
          }
          .descContent {
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            // margin-bottom: 0.5rem;
          }
          .descToggle:checked + .descContent {
            display: block;
          }
        }
      }
    }
  }
}

.tasteContent {
  padding: 0 1rem;
}

/* @media screen and (min-width: 768px) {
  .poiInfoText {
    margin-left: 2rem;
    .poiInfo {
      ul {
        li {
          .header {
            display: block;
          }
          .content {
            width: calc(100% - 7rem);
          }
        }
      }
    }
    .bull {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
} */
