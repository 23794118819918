.offerCard {
  position: relative;
  margin: 0 auto;
  height: 500px;
  width: 400px;

  .offerCardImgGeneral {
    border-radius: 0.5rem;
    position: relative;

    .offerCardImage {
      border-radius: 1.25rem;
    }

    .offerCardCampaignName {
      position: absolute;
      bottom: 12rem;
      padding: 0.25rem 0.5rem;
      text-align: center;
      color: #fff;
      right: 1rem;
      z-index: 5;
      font-size: 0.75rem;
      background-color: var(--primary-color);
      font-family: Brume;
    }
  }

  .offerCardBottom {
    z-index: 10;
    border: 1px solid rgba(0, 0, 0, 0.0784313725);
    background: #fff;
    width: 100%;
    position: absolute;
    border-radius: 1rem;
    top: 13rem;
    /* padding: 1rem; */
    left: 0;
    right: 0;
    width: 100%;

    .bottomContainer {
      padding: 1rem;

      .offerCardName {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: calc(100% - 5.25rem);
      }

      .offerCardTitle {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }

      .offerType {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        color: #49a0ff;
        font-size: 0.75rem;
        font-weight: 700;
      }

      .offerCardCaption {
        margin: 0.5rem 0;
        padding: 0.25rem 0;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        color: var(--primary-color);
        width: 100%;

        .exclamationIcon {
          // padding-bottom: 0.25rem;
          height: 18px;
          margin-right: 0.5rem;
        }

        .offerDesc {
          /* overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; */
          display: block;
          width: calc(100% - 1rem);
        }
      }

      .offerCardDate {
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 20px;
      }

      .loadingWrapper {
        width: 5rem;
        border-radius: 0.5rem;
        padding: 0.25rem;
        background-color: var(--primary-color);
        margin-left: 35%;
      }

      .offerButton {
        background-color: var(--primary-color);
        color: #fff;
        padding: 0.5rem 1rem;
        opacity: 0.7;
        cursor: not-allowed;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .offerCard {
    transform: translateX(-12px);
  }
}
