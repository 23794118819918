.fullCenter {
  padding: 5rem 0;
}

.container {
  overflow: hidden;
  padding: 0 0 1rem 0;
  max-width: 410px;
}

@media only screen and (max-width: 767px) {
  .fullCenter {
    padding: 0 0 6rem;
  }
}
