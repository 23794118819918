.content {
  padding: 0.25rem 0;
}

.flexBetween {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.numberInput {
  width: 6rem;
  text-align: center;
  margin: 0 0.25rem;
}

.campaignDescription {
  color: var(--text-primary-color);

  .iconWrapper {
    display: flex;
    align-items: center;
    svg {
      fill: var(--text-primary-color);
    }
  }
}

.offerTypeItem {
  font-size: 1.125rem;
  line-height: 3rem;
}

.quantityDiv {
  display: flex;
  align-items: center;
}

.campaignDiv {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 3rem;
}
