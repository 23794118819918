.main {
  background-color: #0d31ab;
  min-height: 100%;
}

.header {
  width: 100%;
  height: 90px;
  background-color: #fff;
  box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 0.2);
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.background {
  z-index: 5;
}

.backgroundImage {
  background-image: url('../../assets/img/bob-white.png');
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 200px;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.lineStep {
  // padding: 2rem 0rem;
  ul {
    position: relative;
    z-index: 5;
    // li {
    //   &:after {
    //     background-color: #fff !important;
    //   }
    // }
  }
}

.applicationSelect {
  width: 100% !important;
  height: 56px !important;
  background: #f7f8fc;

  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

.container {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.mainTitle {
  margin-bottom: -1rem;
  font-family: Brume;
  color: var(--background-color);
  letter-spacing: 1px;
}

.title {
  // font-family: Brume;
  color: var(--background-color);
  letter-spacing: 1px;
}

@media only screen and (max-width: 768px) {
  .container {
    top: 1.5rem;
    img {
      height: 3rem !important;
    }
  }

  .lineStep {
    padding: 2rem 3.5rem;
  }

  .stepButtonsDiv {
    width: auto;
    flex-direction: column-reverse;
  }
}
