h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  font-style: normal;
  color: #000000;
}

/* Headers/H1 - 56 - Bold (Open Sans) */
h1 {
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -1px;
}
/* Headers/H2 - 48 - Bold (Open Sans) */
h2 {
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: -1px;
}
/* Headers/H3 - 40 - SemiBold (Open Sans) */
h3 {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3.375rem;
  letter-spacing: -1px;
}
/* Headers/H4 - 32 - SemiBold (Open Sans) */
h4 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.75rem;
  letter-spacing: -0.5px;
}
/* Headers/H5 - 24 - SemiBold (Open Sans) */
h5 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}
/* Headers/H6 - 18 - SemiBold (Open Sans) */
h6 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
