:global {
  .fileContainer {
    background: #e3e4ec !important;
    border-radius: 1.875rem;
    height: 15.625rem;
  }
}

.fileUploader {
  width: 100%;

  .fileContainer {
    background: #e3e4ec !important;
    border-radius: 1.875rem;
    height: 15.625rem;
    box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 5%);
    position: relative;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto;
    transition: all 0.3s ease-in;

    .uploadIcon {
      width: 50px;
      height: 50px;
    }

    .uploadLabel {
      color: var(--text-primary-color) !important;
      font-size: 12px;
      margin: 8px 0 4px;
      font-weight: 600;
      line-height: 1.6rem;
      font-family: Open Sans;
    }

    .chooseFileButton {
      background-color: var(--text-primary-color) !important;
      width: 9rem;
      height: 3rem;
      margin: 10px 0;
      border-radius: 1rem !important;
      cursor: pointer;
    }
  }
}

.offerUploadedImageDiv {
  height: 15.625rem;
  margin: 10px auto;

  .offerImageUploadedClose {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 3.5rem;
    background-color: white;
    color: black;
    border-radius: 3.125rem;
    text-align: center;
    z-index: 999;
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
  }

  .offerUploadedImage {
    width: 100%;
    border-radius: 1.875rem;
    height: 15.625rem;
    object-fit: contain;
    border: 1px solid rgba(204, 204, 204, 0.8);
  }
}

.errorMessage {
  font-size: 1rem;
  color: red;
  margin-top: 0.5rem;
  text-align: center;
}

.imageButton {
  background-color: var(--text-primary-color) !important;
  width: 9rem;
  height: 3rem;
  border-radius: 1rem !important;
  padding: 1rem, 2rem;
}

.imageDiv {
  margin: 10px auto;
  height: 15.625rem;
  img {
    width: 100%;
    border-radius: 1.875rem;
    height: 15.625rem;
    object-fit: contain;
    border: 1px solid #cccccccc;
  }
}

.imageLabel {
  color: var(--text-primary-color) !important;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  font-family: Open Sans;
}

.closeDiv {
  position: absolute;
  width: 2rem;
  height: 2rem;
  /* bottom: 2rem; */
  right: 3.5rem;
  background-color: white;
  color: black;
  border-radius: 3.125rem;
  text-align: center;
  z-index: 1;
  margin-top: 1rem;
  cursor: pointer;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  // margin-top: 0.5rem;
}
