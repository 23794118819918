.reportBody {
  position: relative;
}

.reportBodyItem {
  max-width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reportBodyItem::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 0.25rem;
  font-size: 0.9em;
  padding: 1px 5px;
  display: none;
  color: white;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  transition: opacity 0.1s ease-out;
  z-index: 9999;
  text-align: left;
  user-select: all;
}

.reportBodyItem:hover::before {
  display: inline-block;
}
