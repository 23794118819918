.textArea {
  position: relative;
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1875rem;
  border-radius: 0.25rem;
  height: fit-content;
  border: 0.0625rem solid;
  transition: border-color 500ms ease;
  width: 100%;
  overflow: hidden;
  color: var(--text-primary-color);
  font-family: var(--font-family);
  font-size: 0.875rem;
  padding: 0.5625rem 0.875rem;

  &:hover {
    border-color: rgba(#000000, 0.8);
  }
  &:focus-within {
    border-color: rgba(#000000, 0.8);
  }

  ::placeholder {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    font-style: normal;
    font-weight: normal;
    color: var(--text-primary-color);
    font-family: var(--font-family);
  }
}

.extraText {
  text-align: right;
}
