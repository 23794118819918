.fullCenter {
  padding: 8rem 0 0;
}

.flex {
  display: flex;
}

.buttonsGroupDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.passwordDiv {
  position: relative !important;
}

@media only screen and (max-width: 768px) {
  .fullCenter {
    padding: 1rem 0;
  }

  .container {
    padding: 1rem 0.5rem;
  }
  .buttonsGroupDiv {
    flex-direction: column-reverse;
    width: auto;
    button {
      margin-bottom: 1rem;
      min-width: 15rem;
    }
  }
}
