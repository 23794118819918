.errorMessage {
  /* font-size: 0.875rem;
  padding: 1rem 0;
  margin: 1.5rem 0;
  border-radius: 0.375rem;
  color: red;
  background: white; */

  font-size: 0.75rem;
  margin: 0 0 0.5rem 0;
  border-radius: 0.375rem;
  color: red;
  background: white;

  i {
    /* color: red;
    font-size: 1.25rem;
    line-height: 1.25rem;
    padding-right: 0.75rem; */

    color: red;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0 0.4rem 0 0.25rem;
  }
}
