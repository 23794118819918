.content {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0;
  flex-wrap: wrap;
}

.rangeItem {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-color);
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  .rangeBox {
    display: flex;
    border: 1px solid #fff;
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    box-shadow: 0px 0px 0px 1px #12376914;
    box-shadow: 0px 1px 2px 0px #2a3b511f;
    min-width: 7rem;
  }

  .to {
    color: var(--primary-color);
    padding: 0 0.5rem;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--primary-color);
  background: #fff;
  cursor: pointer;
  svg {
    path {
      fill: var(--primary-color) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .content {
    flex-direction: column;
  }

  .campaignCategoryItem {
    width: 100%;
    margin-bottom: 1rem;
  }
}
