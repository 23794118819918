@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&display=swap');

@font-face {
  font-family: 'Herokid';
  src: url('../assets/fonts/Herokid-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Herokid';
  src: url('../assets/fonts/Herokid-SemiBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brume';
  src: url('../assets/fonts/Brume-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
