.labelDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

:global {
  .rSelect {
    width: 100%;
    display: inline-block;
    color: var(--text-primary-color);

    .rSelect__control {
      color: var(--text-primary-color);
      border: none;
      box-shadow: none;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        border-color: rgba(var(--text-primary-color-rgb), 0.8);
      }
      &:focus-within {
        border-color: rgba(var(--text-primary-color-rgb), 0.8);
      }

      .rSelect__value-container {
        height: 56px;
        line-height: 1.9rem;
        border: 0.5px solid #e3e4ec;
        background: #f7f8fc;

        .css-1g6gooi {
          padding: 0;
          margin: 0;
        }

        .rSelect__placeholder {
          line-height: 1.1875rem;
          color: #a9a9a9;
          font-weight: 400;
          font-style: normal;
        }
        .rSelect__single-value {
          line-height: 1.1875rem;
        }
      }

      .rSelect__indicators {
        display: none;
      }
    }

    .rSelect__menu {
      margin: 0;
      width: inherit;
      z-index: 3;
      border: none;
      filter: drop-shadow(8px 24px 60px rgba(167, 170, 196, 0.24));
      background: white;

      .rSelect__menu-list {
        border: none;

        .rSelect__option--is-selected {
          background: white;
        }

        .rSelect__option--is-focused {
          background: #e0ebfd;
        }

        .labelDiv {
          color: var(--text-primary-color) !important;

          .extraOption {
            color: #b0b0b0;
            line-height: 1.375rem;
          }
        }
      }
    }
  }
}
