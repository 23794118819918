.mainDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f8fc;
  border: 0.5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 1rem;
  min-width: 11.875rem;
  height: 3.5rem;
  padding: 1rem;
  cursor: pointer;
  text-transform: capitalize;
  opacity: 0.5;
}

.checked {
  opacity: 1;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.iconDiv {
  display: flex;
  align-items: center;

  .iconText {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .mainDiv {
    font-size: 0.85rem;
  }
}
