$fontWeight: bold;
$emptyColor: #929292;
$fillColor: #929292;

.empty {
  font-weight: 300;
  font-size: 0.65rem;
  color: $emptyColor;
}
.fill {
  /* font-weight: $fontWeight; */
  font-weight: 300;
  font-size: 0.85rem;
  color: $fillColor;
}
