@import '../../../styles/common.scss';

.form {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  z-index: 20000;
  background-color: var(--background-color);
  overflow: auto;
}

.fullCenter {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  max-width: unset !important;
  display: flex;
  justify-content: space-between;

  .landingImg {
    background-image: url('../../../assets/img/summerLandingPage.jpg');
    width: 50%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .additionalInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0d31ab;
    text-align: center;
    width: 50%;
    padding: 8rem 0 4rem;
    user-select: none;

    .white {
      margin-top: 0.75rem;
      text-align: center;
      span {
        padding: 0 0.5rem;
        color: var(--background-color) !important;
        filter: drop-shadow(1px 1px 1px #000);
        line-height: 2rem;
        text-transform: initial;
      }
    }
  }
}

.bg {
  background-image: url('../../../assets/img/summerLandingPage.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; /* Ensure the whole image is visible */
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../../assets/img/summerLandingPage.jpg');
    background-size: cover;
    filter: blur(20px);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

.confirmationMessage {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 60%;
  transform: translate(-75%, -20%);
  text-align: center;
  color: white;

  .mainText {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
    margin-bottom: 0.75em;
  }

  .subText {
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .stepButtonsDiv {
    width: auto;
    flex-direction: column-reverse;
  }

  .confirmationMessage {
    top: 10%;
    left: 10%;
    transform: translate(-5%, -10%);
  }

  .fullCenter {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    background-color: #0d31ab;
    .landingImg {
      width: 100%;
      height: 60%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: center;
    }
    .additionalInfoContainer {
      width: 100%;
      padding: 0;
      height: 60%;
      .white {
        span {
          padding: 0 0.5rem;
          color: var(--background-color) !important;
          filter: drop-shadow(1px 1px 1px #000);
          line-height: 2rem;
        }
      }
    }
  }
}
