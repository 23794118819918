@import '../../../../styles/common.scss';
.loader {
  height: 0px !important;
}

.hContainer {
  text-align: center;
  padding: 2rem;
  clear: left;

  .question {
    @extend .body-m;
    color: var(--text-primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }
}

.itemsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .qrCode {
    width: 75%;
    margin: 0;
  }
}

.button {
  margin: 0 auto;
  margin-bottom: 3rem;
}

.customer {
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #afafaf;
}

@media only screen and (max-width: 767px) {
  .itemsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    .qrCode {
      width: 50%;
      margin: 0;
    }
  }
  .hContainer {
    text-align: center;
    padding: 1rem;
    clear: left;
  }
}
