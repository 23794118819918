/* Body/M - 16 - Regular (Lato) */
.body-m {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}
/* Body/S - 14 - Medium (Lato) */
.body-s {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
/* Buttons/M - 14 - Bold (Open Sans) */
.button-m {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-primary-color);
}
/* Buttons/S - 13 - Bold (Open Sans) */
.button-s {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.25;
  color: var(--text-primary-color);
}
/* Captions/M - 13 - SemiBold (Lato) */
.caption-m {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: var(--text-primary-color);
}
/* Captions/S - 12 - SemiBold (Lato) */
.caption-m {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--text-primary-color);
}
/* Header 2 - SemiBold - 16 */
.header2 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
/* Body 3 - Regular - 12 */
.body3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--text-primary-color);
}

/* Headers/H5 - 24 - SemiBold (Open Sans) */
.header5 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}

/* Headers/H6 - 18 - SemiBold (Open Sans) */
.header6 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

// ex
.center {
  align-items: center;
  text-align: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.w-full {
  width: 100%;
}
