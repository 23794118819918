.offerItemList {
  width: 100%;
  min-height: 5rem !important;

  &.offerItemListHide {
    min-height: 0px !important;
    height: 0px !important;
    width: 0px !important;
    overflow: hidden !important;
  }

  .emptyMessageDiv {
    text-align: center;
    transform: translateY(50%);
    height: 50%;

    .emptyMessage {
      margin: 2rem;
    }
  }

  .formContainer {
    padding: 0;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .loadMoreBtn {
    margin: 0 auto;
  }
}
