.container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* width: 100%;
  height: 100%; */
  background-color: #222222aa;
  user-select: none;
  z-index: 100000;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    width: 64px;
    height: 64px;
  }
}
