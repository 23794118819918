.reporTableHeader {
  cursor: pointer;

  th {
    font-weight: 600 !important;
  }

  .reporTableHeaderContent {
    display: flex;
    align-items: center;
  }

  .tableSmallWidth {
    min-width: 2.5rem;
  }

  .tableMediumWidth {
    min-width: 9rem;
  }
}
