.container {
  padding: 2rem 6rem;

  .progressbar {
    counter-reset: step;

    li {
      list-style-type: none;
      /* width: 25%; */
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #7d7d7d;

      &:before {
        /* width: 3rem;
        height: 3rem; */
        content: counter(step);
        counter-increment: step;
        /* line-height: 3rem; */

        display: block;
        text-align: center;
        margin: 0 auto;
        border-radius: 50%;

        font-size: 1.1rem;
        font-family: 'Open Sans';
        font-weight: bold;
        /* z-index: 10; */

        color: var(--text-primary-color);
        background: #f7f8fc;
        /* Main/Stroke */

        border: 1px solid #e3e4ec;
        box-sizing: border-box;

        width: 2.8rem;
        height: 2.8rem;
        line-height: 2.8rem;
      }

      &:after {
        width: 80%;
        height: 2px;
        content: '';
        position: absolute;
        top: 1.6rem;
        left: -40%;
        z-index: -1;
        background-color: #7d7d7d;
      }

      &:first-child {
        &:after {
          content: none;
        }
      }

      &.active {
        color: var(--primary-color);

        &:before {
          border: 5px double white;
          background-color: var(--primary-color);
          color: white;
          width: 3.3rem;
          height: 3.3rem;
          line-height: 2.8rem;
        }
        &:after {
          background-color: var(--primary-color);
        }
      }
    }
  }
}
