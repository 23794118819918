.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.375rem;
  border-radius: 0.9375rem;
  border: none;
  text-transform: capitalize;
  min-width: 11.875rem;
  cursor: pointer;
  user-select: none;

  &.primary {
    background-color: var(--primary-color);
    color: white;

    &:disabled {
      background-color: rgba(var(--primary-color-rgb), 0.5);
      color: white;
    }
  }

  &.secondary {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid #e3e4ec;

    &:disabled {
      background-color: rgba(var(--primary-color-rgb), 0.5);
      color: white;
    }
  }

  &.fluid {
    width: 100%;
  }

  &.small {
    font-size: 0.8125rem;
    line-height: 1.25;
  }
}
