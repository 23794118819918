@import '~toastr/build/toastr.min.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

@import '~@fortawesome/fontawesome-free/css/all.css';

@import './styles/fonts.scss';
@import './styles/common.scss';
@import './styles/header.scss';
@import './styles/margin.scss';
@import './styles/padding.scss';
@import './styles/row-col.scss';

html,
body {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  /* required to prevent rogue scrollbars */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  /* cosmetic stuff: */
  font-family: var(--font-family);
  color: var(--text-primary-color);
  background-color: white;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  /* if you want the content to scroll normally: */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  /* cosmetic stuff: */
  box-sizing: border-box;
}

hr {
  height: 1px;
  background: linear-gradient(90deg, rgba(227, 228, 236, 0.1) 0.14%, #e3e4ec 49.97%, rgba(227, 228, 236, 0.05) 100%);
  border: none;
  margin: 1.5rem 0;
}

.image-gallery-content {
  margin-bottom: 3rem;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 30px;
}
.image-gallery-swipe {
  border-radius: 2rem;
  overflow: hidden;
}
.image-gallery-bullets {
  bottom: -2rem;
}
.image-gallery-bullets .image-gallery-bullet {
  padding: 4px;
}

.image-gallery-bullets .image-gallery-bullet {
  border: 1px solid var(--primary-color);
  box-shadow: none;
}
.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: none;
  background: var(--primary-color);
}
.image-gallery-bullets .image-gallery-bullet.active:hover {
  background: rgba(var(--primary-color-rgb), 75%);
}
.image-gallery-bullets .image-gallery-bullet:hover {
  transform: scale(1.1);
  border: none;
  background: rgba(var(--primary-color-rgb), 50%);
}

select:focus-visible {
  outline: none;
}

#js-licensing {
  display: none;
}

.CalendarMonth_table {
  tbody,
  tr,
  td {
    vertical-align: middle;
    font-size: 14px !important;
    text-align: center !important;
    color: var(--text-primary-color) !important;
    font-family: var(--font-family);
  }
}

.CalendarMonth {
  .CalendarMonth_table {
    margin-top: 0.5rem;
    .CalendarDay__highlighted_calendar {
      background: rgba(var(--primary-color-rgb), 0.9);
      color: #fff !important;
    }
  }

  .CalendarMonth_caption {
    color: var(--text-primary-color) !important;
  }
}

.MuiStack-root {
  .MuiTextField-root {
    min-width: unset !important;
  }
  .MuiOutlinedInput-root {
    width: fit-content !important;
  }
}

.MuiTableContainer-root::-webkit-scrollbar {
  height: 1px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid darkgrey;
}
