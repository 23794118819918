.center {
  text-align: center;
}

.fullCenter {
  padding-bottom: 5rem;
}

.float {
  position: fixed;
  width: 4rem;
  height: 4rem;
  bottom: 1rem;
  right: 1.5rem;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 3.125rem;
  text-align: center;
  z-index: 999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uu {
  /* background-color: #fff4f4; */
  min-height: 20rem;
  padding: 1rem;
}
