@import '../../styles/common.scss';

.iconRow {
  display: flex;
  align-items: center;
  padding-top: 0.75rem;

  .icon {
    margin-right: 0.5rem;
  }

  .header {
    @extend .body-m;
    font-weight: 600;
    margin-left: 0.5rem;
  }

  .separater {
    flex: 1;
  }

  .text {
    @extend .body-m;
    max-width: 75%;
    text-align: right;
  }
}
