.rSelectlabelDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraLabel {
  margin-left: 0.5rem !important;
}

:global {
  .trpReactSelect {
    width: 100%;
    display: inline-block;
    color: var(--text-primary-color);

    .trpReactSelect__control {
      color: var(--text-primary-color);
      border: 0.5px solid #e3e4ec;
      border-radius: 0.9375rem;
      background: #f7f8fc;
      box-shadow: none;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        border-color: #e3e4ec;
      }
      &:focus-within {
        border-color: #e3e4ec;
      }

      .trpReactSelect__value-container {
        // padding: 0.5625rem 0.875rem;
        height: 56px;
        line-height: 1.9rem;
        border-radius: 0.9375rem;

        .css-1g6gooi {
          padding: 0;
          margin: 0;
        }

        .trpReactSelect__placeholder {
          line-height: 1.1875rem;
          color: #a9a9a9;
          font-weight: 400;
          font-style: normal;
        }
        .trpReactSelect__single-value {
          line-height: 1.1875rem;
          top: 55%;
        }
      }

      .rSelect__indicators {
        display: none;
      }
    }

    .trpReactSelect__menu {
      margin: 0;
      width: inherit;
      padding: 0.475rem 0rem 0.475rem 0rem;
      z-index: 3;
      border: none;
      /* box-shadow: 0px 24px 90px rgba(198, 200, 217, 0.2); */
      filter: drop-shadow(8px 24px 60px rgba(167, 170, 196, 0.24));
      border-radius: 0.9375rem;
      background: white;
      margin-top: 0.5rem !important;
      cursor: pointer;

      .rSelect__menu-list {
        border: none;
        margin: 0 0.5rem !important;

        .rSelect__option {
          padding: 0.75rem !important;
        }

        .rSelect__option--is-selected {
          background: #f7f8fc;
          border-radius: 1rem;
        }

        .rSelect__option--is-focused {
          background: #f7f8fc;
          border-radius: 1rem;
        }
      }
    }
  }
}
