.input {
  position: relative;
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1875rem;
  /* border-radius: 0.375rem; */
  border-radius: 0.9375rem;
  height: fit-content;
  /* border: 0.0625rem solid #afafaf; */
  border: 0.5px solid #e3e4ec;
  transition: border-color 500ms ease;
  width: 100%;
  overflow: hidden;
  background: #f7f8fc;
  outline: none;
  color: var(--text-primary-color);
  font-family: var(--font-family);

  /* Main/Stroke */

  /* &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible {} */
}

::placeholder {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: normal;
  color: var(--text-primary-color);
  font-family: var(--font-family);
}

input:disabled {
  opacity: 0.5;
}

.default {
  font-size: 0.875rem;
  padding: 0.5625rem 0.875rem;
}

.small {
  font-size: 0.8125rem;
  padding: 0.3755rem 0.875rem;
}

.large {
  font-size: 0.9375rem;
  padding: 0.7275rem 0.875rem;
  height: 56px;
}
