@import '../../../styles/common.scss';

.fullCenter {
  padding: 8rem 0 4rem;
}
.newPasswordContainer {
  text-align: center;
  padding-bottom: 2rem;

  .newPasswordGoBack {
    @extend .body-m;
    color: var(--text-primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }
}

.formContainer {
  .inputDiv {
    padding-bottom: 1.5rem;
  }
}

.newPasswordSuccessMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newPasswordButton {
  margin: 1rem auto 0;
}

.newPasswordSuccessMessageHeader {
  margin-bottom: 1rem;
  text-align: center;
}

.newPasswordSuccessMessageText {
  margin-bottom: 2rem;
  text-align: center;
}
