@import '../../styles/common.scss';

.cardContainer {
  background: #fff;
  box-shadow: 0 24px 90px rgba(198, 200, 217, 0.2);
  border-radius: 30px;
  margin: 1rem 10rem;
  padding: 2rem 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
}

.imageContainer {
  border-radius: 0.125rem;
  border: solid 1px #f5f5f5;
  img {
    width: 12rem;
    height: 12rem;
  }
}

.name {
  font-weight: 500;
  font-size: 1.25rem;
}
.size {
  margin-top: 2rem;
}
.accomodation {
  margin-top: 1.25rem;
}

.arrow {
  // display: flex;
  // flex-direction: row;
  cursor: pointer;
  // justify-content: flex-end;
  // align-items: center;
  // margin-left: auto;
  // margin-right: 0.5rem;
  // width: 15rem;
  // height: 12rem;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.downloadWrapper {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;

  a {
    margin-right: 0.5rem;
    text-decoration: none;
    color: #49a0ff;
  }
}

@media only screen and (min-width: 768px) {
  .cardContainer {
    min-width: 450px;
    width: 60%;
  }
}
