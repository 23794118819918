/* mobile public layout container */
.container {
  position: relative;
  height: 100%;
  min-width: 370px;
  overflow: auto;

  .publicLogo {
    // height: 3rem;
    width: auto;
    padding: 2.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // .publicLogo {
  //   position: absolute;
  //   height: 2rem;
  //   width: auto;
  //   top: 2.5rem;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}

/* desktop public layout container */
@media only screen and (min-width: 768px) {
  .container {
    position: relative;
    height: 100%;
    min-width: 370px;

    .publicLogo {
      left: 0;
      transform: none;
    }
  }
}

/* mobile private layout container */
.pcontainer {
  display: grid;
  position: relative;
  grid-template-columns: 0 1fr;
  grid-template-areas: 'sidebar content';
  height: 100%;

  .sideMenu {
    position: absolute;
    z-index: 1000;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    transition: height 0.2s ease 0s;

    /* common */
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: #140f21;
    color: var(--text-primary-color);

    &.close {
      height: 4.5rem;
    }

    .sideMenuContent {
      // display: flex;
      // flex-direction: column;
      height: 100%;

      .logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-top: 1rem;
        height: 100%;
        img {
          padding-left: 0.5rem;
          max-width: 16rem;
        }
        .toggle {
          float: right;
          height: 4rem;
          width: 4rem;
          margin: -0.75rem 0.25rem;
          text-align: center;
          cursor: pointer;
        }
      }

      .adminTools {
        padding: 1.5rem 0 1rem 2.5rem;
        font-size: 0.75rem;
        color: #808191;
      }

      .menu {
        padding: 0 1.25rem;

        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--text-primary-color);

        .menuItem {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 1rem 0 1rem 1.5rem;
          margin-bottom: 1rem;
          border-radius: 8px;
          cursor: pointer;
          color: #808191;

          svg {
            padding-right: 1rem;
          }

          &.active {
            color: #ffffff;
            background: #ffffff19;
          }
        }
      }

      .footerSpace {
        flex: 1;
      }
      .footer {
        width: 100%;
        border-top: 1px solid #ffffff1a;

        .tos {
          display: block;
          height: 1.75rem;
          padding: 1rem 0 0 3rem;
          font-size: 0.85rem;
          border-bottom: 1px solid #ffffff1a;
          cursor: pointer;
          color: #808191;
          text-decoration: none;
        }

        .logout {
          display: flex;
          align-items: center;
          margin: 1.5rem;
          padding: 1.5rem;
          cursor: pointer;
          color: #808191;

          svg {
            padding-right: 0.75rem;
          }
        }
      }
    }
  }

  .content {
    padding: 4.5rem 0 0 0;
    // common
    grid-area: content;
    position: relative;
    margin: 0;
    color: var(--text-primary-color);
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) {
  .pcontainer {
    grid-template-columns: 17rem 1fr;

    .sideMenu {
      position: relative;
      z-index: auto;
      top: auto;
      bottom: auto;
      left: auto;

      &.close {
        height: auto;
      }

      .sideMenuContent {
        .logo {
          display: block;
          height: unset;
          padding-top: 1rem;
          //padding: 2.5rem !important;
          text-align: center;
          img {
            padding: 0;
            max-width: 16rem;
          }

          .toggle {
            display: none;
          }
        }

        .footer {
          position: absolute;
          bottom: 0;
        }
      }
    }

    .content {
      padding: 0;
    }
  }
}
