/**
  *
  * margin left
  *
  */
.ml0 {
  margin-left: 0 !important;
}
.ml1 {
  margin-left: 0.25rem !important;
}
.ml2 {
  margin-left: 0.5rem !important;
}
.ml3 {
  margin-left: 0.75rem !important;
}
.ml4 {
  margin-left: 1rem !important;
}
.ml5 {
  margin-left: 1.25rem !important;
}
.ml6 {
  margin-left: 1.5rem !important;
}
.ml7 {
  margin-left: 1.75rem !important;
}
.ml8 {
  margin-left: 2rem !important;
}
.ml9 {
  margin-left: 2.25rem !important;
}
.ml10 {
  margin-left: 2.5rem !important;
}
.ml11 {
  margin-left: 2.75rem !important;
}
.ml12 {
  margin-left: 3rem !important;
}
/**
    *
    * margin right
    *
    */
.mr0 {
  margin-right: 0 !important;
}
.mr1 {
  margin-right: 0.25rem !important;
}
.mr2 {
  margin-right: 0.5rem !important;
}
.mr3 {
  margin-right: 0.75rem !important;
}
.mr4 {
  margin-right: 1rem !important;
}
.mr5 {
  margin-right: 1.25rem !important;
}
.mr6 {
  margin-right: 1.5rem !important;
}
.mr7 {
  margin-right: 1.75rem !important;
}
.mr8 {
  margin-right: 2rem !important;
}
.mr9 {
  margin-right: 2.25rem !important;
}
.mr10 {
  margin-right: 2.5rem !important;
}
.mr11 {
  margin-right: 2.75rem !important;
}
.mr12 {
  margin-right: 3rem !important;
}
/**
    *
    * margin x
    *
    */
.mx0 {
  @extend .ml0, .mr0;
}
.mx1 {
  @extend .ml1, .mr1;
}
.mx2 {
  @extend .ml2, .mr2;
}
.mx3 {
  @extend .ml3, .mr3;
}
.mx4 {
  @extend .ml4, .mr4;
}
.mx5 {
  @extend .ml5, .mr5;
}
.mx6 {
  @extend .ml6, .mr6;
}
.mx7 {
  @extend .ml7, .mr7;
}
.mx8 {
  @extend .ml8, .mr8;
}
.mx9 {
  @extend .ml9, .mr9;
}
.mx10 {
  @extend .ml10, .mr10;
}
.mx11 {
  @extend .ml11, .mr11;
}
.mx12 {
  @extend .ml12, .mr12;
}

/**
    *
    * margin top
    *
    */
.mt0 {
  margin-top: 0 !important;
}
.mt1 {
  margin-top: 0.25rem !important;
}
.mt2 {
  margin-top: 0.5rem !important;
}
.mt3 {
  margin-top: 0.75rem !important;
}
.mt4 {
  margin-top: 1rem !important;
}
.mt5 {
  margin-top: 1.25rem !important;
}
.mt6 {
  margin-top: 1.5rem !important;
}
.mt7 {
  margin-top: 1.75rem !important;
}
.mt8 {
  margin-top: 2rem !important;
}
.mt9 {
  margin-top: 2.25rem !important;
}
.mt10 {
  margin-top: 2.5rem !important;
}
.mt11 {
  margin-top: 2.75rem !important;
}
.mt12 {
  margin-top: 3rem !important;
}
/**
    *
    * margin bottom
    *
    */
.mb0 {
  margin-bottom: 0 !important;
}
.mb1 {
  margin-bottom: 0.25rem !important;
}
.mb2 {
  margin-bottom: 0.5rem !important;
}
.mb3 {
  margin-bottom: 0.75rem !important;
}
.mb4 {
  margin-bottom: 1rem !important;
}
.mb5 {
  margin-bottom: 1.25rem !important;
}
.mb6 {
  margin-bottom: 1.5rem !important;
}
.mb7 {
  margin-bottom: 1.75rem !important;
}
.mb8 {
  margin-bottom: 2rem !important;
}
.mb9 {
  margin-bottom: 2.25rem !important;
}
.mb10 {
  margin-bottom: 2.5rem !important;
}
.mb11 {
  margin-bottom: 2.75rem !important;
}
.mb12 {
  margin-bottom: 3rem !important;
}
/**
    *
    * margin y
    *
    */
.my0 {
  @extend .mt0, .mb0;
}
.my1 {
  @extend .mt1, .mb1;
}
.my2 {
  @extend .mt2, .mb2;
}
.my3 {
  @extend .mt3, .mb3;
}
.my4 {
  @extend .mt4, .mb4;
}
.my5 {
  @extend .mt5, .mb5;
}
.my6 {
  @extend .mt6, .mb6;
}
.my7 {
  @extend .mt7, .mb7;
}
.my8 {
  @extend .mt8, .mb8;
}
.my9 {
  @extend .mt9, .mb9;
}
.my10 {
  @extend .mt10, .mb10;
}
.my11 {
  @extend .mt11, .mb11;
}
.my12 {
  @extend .mt12, .mb12;
}
/**
    *
    * margin
    *
    */
.m0 {
  @extend .mx0, .my0;
}
.m1 {
  @extend .mx1, .my1;
}
.m2 {
  @extend .mx2, .my2;
}
.m3 {
  @extend .mx3, .my3;
}
.m4 {
  @extend .mx4, .my4;
}
.m5 {
  @extend .mx5, .my5;
}
.m6 {
  @extend .mx6, .my6;
}
.m7 {
  @extend .mx7, .my7;
}
.m8 {
  @extend .mx8, .my8;
}
.m9 {
  @extend .mx9, .my9;
}
.m10 {
  @extend .mx10, .my10;
}
.m11 {
  @extend .mx11, .my11;
}
.m12 {
  @extend .mx12, .my12;
}
