.customInput {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  border-color: #0000003b;
  padding: 5.6px 8px;
  max-width: 7rem;
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 1) !important;
  }
}

.amountInput {
  max-width: 3.25rem !important;
}

.customInput::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 0.75rem;
}

.tableHeader {
  font-weight: 600 !important;
}

.filterRow {
  th {
    padding-top: 0.5rem !important;
    padding-bottom: 1rem !important;
  }
}

.filterRowDatePicker {
  min-width: 0 !important;
  max-width: 160px !important;

  [data-shrink='false'] {
    top: -9px !important;
    left: -8px !important;
    font-size: 0.875rem !important;
  }

  input {
    padding: 8px !important;
    font-size: 0.875rem !important;
  }

  button {
    padding: 0 !important;
    svg {
      width: 0.875em !important;
      height: 0.875em !important;
    }
  }

  input::placeholder {
    font-size: 0.875rem !important;
  }
}
