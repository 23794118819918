.content {
  padding: 0.75rem 0;
  cursor: pointer;

  select {
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--text-primary-color);
    text-indent: 1px;
    border: 0.5px solid #e3e4ec;
    background: #f7f8fc;
    height: 56px;
    width: 100%;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.25rem center;
    background-size: 1em;
  }
}
