.tableHeader {
  cursor: pointer;

  th {
    font-weight: 600 !important;
  }

  .tableHeaderContent {
    display: flex;
    align-items: center;
  }

  .tableSmallWidth {
    min-width: 2.5rem;
  }

  .tableMediumWidth {
    min-width: 9rem;
  }
}
