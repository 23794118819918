.offerFormMain {
  padding: 1rem;
}

.stepButtonsDiv {
  width: 85%;
  padding-top: 1.5rem;
}

.continueButton {
  margin-bottom: 1rem;
}

.offerTypeLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateTimePickerCustom {
  .e-input-group {
    border: 1px solid !important;
    border-radius: 6px !important;
    padding: 0.2rem !important;
  }
}

.quantityDiv {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 1rem;

  .quantityCheck {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }
}

.datePickerLabel {
  font-size: 1.125rem;
}

.numberInput {
  width: 45% !important;
  text-align: center;
  margin: 0 0.25rem;
  margin-bottom: 1rem;
}

.pageHeader {
  font-weight: 600;
  text-align: center;
  font-size: 2rem;
  padding: 1.5rem 0;
}

.titleWithCheckbox {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  .checkBoxInput {
    width: unset;
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  .lineStep {
    padding: 2rem 0 !important;
  }

  .stepButtonsDiv {
    width: auto;
    flex-direction: column-reverse;
  }
}
