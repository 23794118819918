@import '../../../styles/common.scss';

.fullCenter {
  padding: 2rem;
  margin: 3rem auto;
  min-height: 7rem;
  position: relative;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  background: #fff;
  box-shadow: 0 24px 90px rgba(198, 200, 217, 0.2);
  border-radius: 30px;
  min-height: 20rem;
  padding: 2rem 0;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 380px;
}

.campaignHeader {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.campaignDropdownContent {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.campaignDropdown {
  margin-bottom: 0.25rem;
}

.detailsHeader {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
}

.tableWrapper {
  width: 90%;
  margin-bottom: 1rem;
}

.travelerCount {
  display: flex;
  align-items: center;
  width: 100%;
}

.accordionRow {
  position: relative;
  margin-bottom: 1rem;
  li {
    list-style-type: none;
    padding: 0.5rem;
  }
}

.statusApproved {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  color: var(--success-color);
}

.statusRejected {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  color: var(--danger-color);
}
.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 2.5rem;
}
.approveButton {
  background-color: var(--success-color);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: #f5f5f5;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  width: fit-content;
  padding: 0.5rem;
}

.approveButtonDisabled {
  background-color: var(--success-color);
  opacity: 0.4;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  color: #f5f5f5;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  width: fit-content;
  padding: 0.5rem;
}

.rejectButton {
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  text-align: center;
  color: #f5f5f5;
  font-size: 0.75rem;
  width: 5rem;
  background-color: var(--danger-color);
}

.rejectReason {
  margin-top: 1rem;
}

.approveModalContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem;
  margin-top: 0.5rem;

  .bookingDatePicker {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    color: var(--text-primary-color) !important;

    &:focus {
      border-color: var(--text-primary-color) !important;
    }
  }
  .maxAmountContent {
    margin-bottom: 0.5rem;
    position: relative;

    .maxAmountTitle {
      position: absolute;
      top: -1.25rem;
      left: 0;
      font-size: 0.875rem;
    }

    .maxAmountInput {
      line-height: 2.3125rem;
      background: #fff;
      min-width: 14rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: var(--text-primary-color);
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.87);
      }
      &:focus {
        border: 2px solid var(--text-primary-color);
      }
      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .fullCenter {
    margin: 3rem;
  }

  .detailsWrapper {
    width: 100%;
  }

  .tableWrapper {
    width: 60%;
  }

  .campaignDropdown {
    min-width: 15rem;
  }
}
