.content {
  display: flex;
  flex-direction: row;
  gap: 0 2rem;
  padding: 0.75rem 0;
}

@media only screen and (max-width: 767px) {
  .content {
    flex-direction: column;
  }

  .campaignCategoryItem {
    width: 100%;
    margin-bottom: 1rem;
  }
}
