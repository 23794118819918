@import '../../../styles/common.scss';

.fullCenter {
  padding: 8rem 0 4rem;
}
.loginContainer {
  text-align: center;
  padding-bottom: 2rem;

  .question {
    @extend .body-m;
    color: var(--text-primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }
}

.formContainer {
  .inputDiv {
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;
  }

  .eyeIconButton {
    position: absolute;
    right: 3rem;
    cursor: pointer;
  }

  button {
    margin: 1rem auto 0;
  }
}
