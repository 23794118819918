.linkButtonBold {
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* display: flex;
  align-items: center;
  text-align: center; */
  text-decoration-line: underline;

  /* Main/Violet */

  color: var(--primary-color);
}

.linkButton {
  color: var(--text-primary-color);
  cursor: pointer;
  /* line-height: 1.1875px; */
  text-decoration-line: underline;
  /* padding-bottom: 0.375rem; */

  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  text-align: right;
  text-decoration-line: underline;

  /* Main/Violet */

  color: var(--primary-color);
}
