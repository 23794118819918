@import '../../styles/common.scss';

.campaignItem {
  cursor: pointer;
  padding: 1rem;
  background: #fff;
  box-shadow: 0 24px 90px rgba(198, 200, 217, 0.2);
  border-radius: 30px;
  margin: 0 auto;
  margin-top: 2rem;
  min-width: auto;

  :global {
    .row,
    .col {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .campaignTitle {
    position: relative;
    @extend .body-m;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0;

    svg {
      margin-right: 0.5rem;
    }

    .menu {
      position: absolute;
      top: 1rem;
      right: -1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &:focus-visible {
        outline: none;
      }

      .menuList {
        /* display: flex; */
        display: none;
        flex-direction: column;
        padding: 0.25rem;

        background: #ffffff;
        box-shadow: 4px 20px 65px -4px rgba(194, 196, 214, 0.28);
        border-radius: 20px;

        &:focus-visible {
          outline: none;
        }

        .menuItem {
          padding: 0 0.75rem 0.75rem;
          /* filter: grayscale(1); */
          // opacity: 0.5;

          &:hover {
            filter: none;
            // opacity: 1;
            background: #f7f8fc;
            border-radius: 15px;
          }
        }
      }

      &:focus-within {
        outline: none;

        /* .menuList {
          display: flex;
        } */
      }

      .menuListOpen {
        display: flex;
      }
    }
  }

  .campaignHeader {
    width: calc(100% - 2rem);
    text-transform: capitalize;
    padding-bottom: 0.5rem;
  }

  hr {
    height: 1px;
    background: linear-gradient(90deg, rgba(227, 228, 236, 0.1) 0.14%, #e3e4ec 49.97%, rgba(227, 228, 236, 0.05) 100%);
    border: none;
    margin: 1.5rem 0;
  }
}

@media only screen and (min-width: 768px) {
  .campaignItem {
    min-width: 450px;
    width: 80%;

    .campaignTitle {
      .menu {
        right: -0.75rem;
        &:hover {
          outline: none;

          .menuList {
            display: flex;
          }
        }
      }
    }
  }
}
