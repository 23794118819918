.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f8fc;
  border: 1px solid #e8e7e9;
  // color: var(--text-primary-color) !important;
  box-sizing: border-box;
  border-radius: 1rem;
  height: 4rem;
  padding: 0 5px 0 6px;
  cursor: pointer;
  text-transform: capitalize;

  min-width: 3rem;
}

.iconDiv {
  .iconText {
    opacity: 0.5;
  }
}

.checked {
  opacity: 1;
  background: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  min-width: 3rem;

  .iconDiv {
    display: flex;
    align-items: center;

    .iconText {
      margin-left: 0;
      color: var(--primary-color) !important;
      opacity: 1;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .mainDiv {
    font-size: 0.85rem;
  }
}
