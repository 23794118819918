$speed: 0.6s;
$delay: ($speed * 0.5);
$easing: cubic-bezier(0.55, 0, 0.1, 1);

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(#000, 0.6);
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  transition: opacity $speed $easing, visibility $speed $easing;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}
// media query

/**
 * Modal
 */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  width: 600px;
  max-width: 75rem;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  opacity: 0;
  overflow-y: auto;
  visibility: hidden;
  box-shadow: 0 2px 10px rgba(#000, 0.1);
  backface-visibility: hidden;
  transform: scale(1.2);
  transition: all $speed $easing;
  text-align: center;
  line-height: 1.2;

  .closeModal {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 15px;
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity $speed $easing, transform $speed $easing;
    transition-delay: $delay;

    svg {
      width: 1.75em;
      height: 1.75em;
    }
  } // close modal

  .modalContent {
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity $speed $easing;
    transition-delay: $delay;
  } // content

  &.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    padding: 1rem !important;

    .modalContent {
      opacity: 1;
    }

    .closeModal {
      transform: translateY(10px);
      opacity: 1;
    }
  }
}

.buttonsDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 0;
}

/**
 * Mobile styling
 */
@media only screen and (max-width: 39.9375em) {
  h1 {
    font-size: 1.5rem;
  }

  .modal {
    /* position: fixed;
    top: 15%;
    left: 20px; */
    width: 90%;
    /* height: 50%;
    -webkit-overflow-scrolling: touch;
    border-radius: 1rem;
    transform: scale(1.1);
    padding: 0 !important;
    z-index: 999; */
  }

  .closeModal {
    right: 20px !important;
  }

  .buttonsDiv {
    flex-direction: column-reverse;
    align-items: inherit;
  }
}
