@import '../../../styles/common.scss';

.hContainer {
  text-align: center;
  clear: left;
  padding-bottom: 2rem;
  .question {
    @extend .body-m;
    color: var(--text-primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
  }
  h4 {
    padding: 4rem 0 0.5rem;
  }
}

.formContainer {
  h5 {
    color: var(--primary-color);
  }
  hr {
    height: 1px;
    background: linear-gradient(90deg, rgba(227, 228, 236, 0.1) 0.14%, #e3e4ec 49.97%, rgba(227, 228, 236, 0.05) 100%);
    border: none;
    margin: 1.5rem 0;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
  }
  .desc {
    @extend .body-m;
    color: var(--text-primary-color);
    display: block;
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .lineStep {
    padding: 6rem 0 !important;
  }
}

.error {
  color: rgb(182, 0, 0);
}
.success {
  color: rgb(4, 165, 4);
}

@media only screen and (max-width: 767px) {
  .formContainer {
    .buttons {
      flex-direction: column-reverse;

      .col12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
