.MENU_OFFERS {
  fill: var(--warning-color);
}

.MENU_BUSINESS {
  fill: var(--danger-color);
}

.MENU_BUSINESS_REPORT {
  fill: var(--primary-color);
}

.MENU_USER {
  fill: var(--success-color);
}

// .MENU_LOGOUT {
// }

.FOOD {
  fill: var(--primary-color);
}

.CART {
  fill: none;
  g {
    path {
      stroke: var(--primary-color);
    }
  }
}

// .MENU {
// }

// .CLOSE {
// }

.VECTOR {
  fill: var(--primary-color);
}

.CLOCK {
  fill: var(--primary-color);
}

.CAR {
  fill: var(--primary-color);
}

.COMPONENT {
  fill: var(--primary-color);
}

.USER_TICK {
  fill: var(--primary-color);
}

.MENU_CARD {
  rect:nth-child(2) {
    fill: var(--primary-color);
  }
}

.EYE {
  fill: var(--primary-color);
}

.GALLERY {
  fill: var(--primary-color);
}

.COPY {
  fill: none;
  g {
    path {
      stroke: var(--primary-color);
    }
  }
}

.BOOK_SAVED {
  fill: var(--primary-color);
}

.TRASH {
  fill: none;
  g {
    path {
      stroke: var(--primary-color);
    }
  }
}

.EYE_SLASH {
  fill: none;
  g {
    path {
      stroke: var(--primary-color);
    }
  }
}

// .CLOSE_THEME {
// }

.LOCATION {
  fill: var(--primary-color);
}

.FOOD_THEME {
  fill: var(--primary-color);
}

.DRINKS {
  fill: var(--primary-color);
}

.CALL {
  fill: var(--primary-color);
}

// .HOURS {
// }

// .CALL2 {
// }

// .LINK {
// }

.PROFILE {
  fill: var(--primary-color);
}

.PASSWORD {
  fill: var(--primary-color);
}

.DINEIN {
  fill: var(--primary-color);
}

.DOLLAR {
  fill: var(--primary-color);
}

.PERCENT {
  fill: var(--primary-color);
}

.MULTIBUY {
  fill: var(--primary-color);
}

.GIFT {
  fill: var(--primary-color);
}

.DONE {
  g {
    path {
      fill: var(--success-color);
    }
  }
}

.UNDONE {
  stroke: var(--danger-color);
}

.EDIT {
  fill: var(--primary-color);
}

.CAMPAIGN_LIMIT {
  fill: var(--primary-color);
}

.AVAILABLE {
  fill: var(--primary-color);
}

.BOOK_ONLINE {
  fill: var(--primary-color);
}

.LIMITED {
  fill: var(--primary-color);
}

.UNLIMITED {
  fill: var(--primary-color);
}
