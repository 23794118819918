@import '../../styles//common.scss';

.offerCard {
  position: relative;
  width: 21rem;
  height: 18.5rem;
  // cursor: pointer;
  margin: 0 auto;

  .imageContainer {
    .off {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      background: rgb(71, 51, 100);
      background: linear-gradient(-90deg, #473364 35%, #9c7da2 100%);
      color: white;
    }

    .image {
      border: 1px solid #00000014;
      object-fit: contain;
      border-radius: 1.875rem;
      height: 14rem;
    }
  }

  .content {
    position: absolute;
    width: 20rem;
    top: 9rem;
    left: 0.5rem;
    /* z-index: 2; */

    .titleDiv {
      display: flex;
      justify-content: space-between;
    }

    .businessName {
      @extend .header2;
    }

    .receive {
      @extend .body-s;
    }

    .rowc {
      @extend .body3;
    }
  }
}
