.reportCustomInput {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  border-color: #0000003b;
  padding: 5.6px 8px;
  max-width: 7rem;
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 1) !important;
  }
}

.reportAmountInput {
  max-width: 3.25rem !important;
}

.reportCustomInput::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 0.75rem !important;
}

.reportTableHeader {
  font-weight: 600 !important;
}

.reportFilterRow {
  th {
    padding-top: 0.5rem !important;
    padding-bottom: 1rem !important;
  }
}

.reportSelectStatus {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.231372549);
  padding: 7px 8px;
  max-width: 5rem;
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    border: 1px solid rgb(0, 0, 0) !important;
  }
}
