.select {
  height: fit-content;
  transition: border-color 500ms ease;
  background: #f7f8fc;
  border: 0.0625rem solid #e3e4ec;
  line-height: 1.1875rem;
  border-radius: 0.9375rem;
  // appearance: none;
  // background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  // background-repeat: no-repeat;
  // background-position: right 0.25rem center;
  // background-size: 1em;

  &.default {
    font-size: 0.875rem;
    padding: 0.5625rem 0.875rem;
  }

  &.small {
    font-size: 0.8125rem;
    padding: 0.3755rem 0.875rem;
  }

  &.large {
    font-size: 0.9375rem;
    padding: 0.7275rem 0.875rem;
  }
}
