input[type='radio'] {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
}

// input[type='radio']:hover {
//   border-color: var(--primary-color);
// }

// input[type='radio']:selected {
//   border-color: var(--primary-color);
// }

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: var(--primary-color);
}
