@import '../../../styles/common.scss';

.inputLabelDiv {
  padding-bottom: 0.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .inputLabelHtmlFor {
    cursor: pointer;
  }

  .inputLabel {
    /* @extend .caption-m; */
    // font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    color: var(--text-primary-color);
  }
}

.textUppercase {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
