.total {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  .totalRow {
    font-size: 1rem;
    font-weight: bold;
  }
}

.reportLoadingContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  position: absolute;
}

.exportCsv {
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.375rem;
  border-radius: 0.9375rem;
  border: none;
  text-transform: capitalize;
  min-width: 8rem;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  // margin: 1rem auto 0;
  text-decoration: none;
}

.buttonsGroupDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem auto;
}
