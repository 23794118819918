.tabMenu {
  height: 48px;
  display: flex;
  white-space: nowrap;
  position: relative;
  /* text-transform: uppercase; */
  /* font-size: 0.85rem; */
  font-size: 1.125rem;
  color: #9f9ba7;
}

.tabMenuItem {
  flex: 1;
  padding: 0 1rem;
  margin: 0 auto;
  text-align: center;
  display: table;
  height: 100%;
  cursor: pointer;
  max-width: 100%;
  font-weight: 500;

  &:active,
  &:focus {
    border: none;
    outline: 0;
  }
}

.tabMenuItemText {
  vertical-align: middle;
  display: table-cell;
  text-transform: capitalize;
}

.selected {
  border-bottom: 3px solid var(--primary-color);
  height: 100%;
  width: 70%;
  margin: 0 auto;
  min-width: fit-content;
  font-weight: normal;
  color: #3f3750;
}
