@import '../../../styles/common.scss';

.fullCenter {
  padding: 3rem 0 0 0;
}
.welcomeMain {
  text-align: center;
  padding: 2rem 0;

  .hContainer {
    text-align: center;
    padding-top: 2rem;
    clear: left;

    .question {
      @extend .body-m;
      color: var(--text-primary-color);
      padding-top: 0.5rem;
      padding-bottom: 0.75rem;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;
    text-align: center;

    .iconFormContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .iconRow {
      display: flex;
      align-items: center;

      svg {
        padding-right: 1rem;
      }
    }
    .header5 {
      @extend .header5;
    }

    .header6 {
      @extend .header6;
      color: var(--text-primary-color);
    }

    .boxGrid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0.75rem;
    }

    .callText {
      @extend .body-m;
      color: var(--text-primary-color);
      text-align: center;
      margin-top: 2rem;
    }

    .buttonsGroupDiv {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin-top: 3rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .buttonsGroupDiv {
    flex-direction: column-reverse;
    button {
      margin: 0 2rem 1rem;
    }
  }
}
