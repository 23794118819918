@import '../../styles//common.scss';

.offerCard {
  position: relative;
  width: 21rem;
  box-sizing: border-box;
  margin: 0 auto;
  // border: 1px solid #e3e4ec;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.14), 0 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.2);
  // margin-bottom: 2rem;

  .businessName {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 1rem;
    color: #fff;
    line-height: 30px;
    font-size: 1rem;
  }

  .qr {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 7rem;
    width: 7rem;
    background: #fff;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.blurred {
      filter: blur(1.5px);
    }

    svg {
      width: 6.25rem !important;
      height: 6.25rem !important;
    }
  }
}
